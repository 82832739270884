import React from "react"
import Helmet from "react-helmet"

function SEO({ title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      titleTemplate={`Reviews | %s`}
    >
      <meta name="robots" content="noindex" />
    </Helmet>
  )
}

export default SEO
